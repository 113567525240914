import React from 'react';
import '../Modal.css'; // You'll need to update this CSS file to include the new styles
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import icons

const Modal = ({ isOpen, onClose, children, nextImage, prevImage }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="modal-navigation">
                    <FaChevronLeft className="modal-nav-icon left-icon" onClick={prevImage} />
                    {children}
                    <FaChevronRight className="modal-nav-icon right-icon" onClick={nextImage} />
                </div>
            </div>
        </div>
    );
};

export default Modal;
