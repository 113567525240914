import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Modal from './Modal';

const ProjectList = () => {
    const [projects, setProjects] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [currentImages, setCurrentImages] = useState([]);

    const apiUrl = process.env.NODE_ENV === 'development' 
        ? process.env.REACT_APP_API_URL 
        : process.env.REACT_APP_API_URL_DEPLOY;

    useEffect(() => {
        axios.get(`${apiUrl}/projects/`)
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the data!", error);
            });
    }, []);

    const openModal = useCallback((images, index) => {
        setCurrentImages(images);
        setPhotoIndex(index);
        setIsOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const nextImage = useCallback(() => {
        setPhotoIndex((photoIndex + 1) % currentImages.length);
    }, [photoIndex, currentImages.length]);

    const prevImage = useCallback(() => {
        setPhotoIndex((photoIndex + currentImages.length - 1) % currentImages.length);
    }, [photoIndex, currentImages.length]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isOpen) {
                if (event.key === 'ArrowRight') {
                    nextImage();
                } else if (event.key === 'ArrowLeft') {
                    prevImage();
                } else if (event.key === 'Escape') {
                    closeModal();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, nextImage, prevImage, closeModal]);

    return (
        <div className="container-projects">
            <h2 className="mb-4" id='projects-title'>Projets réalisés par SVL construct</h2>
            <div className="row">
                {projects.length > 0 ? (
                    projects.map(project => (
                        <div key={project.id} className="col-md-4 mb-4">
                            <div className="card h-100">
                                {project.images && project.images.length > 0 && (
                                    <Carousel showThumbs={false}>
                                        {project.images.map((img, index) => (
                                            <div key={img.id} className="carousel-image">
                                                <div
                                                    key={img.id}
                                                    className="carousel-image"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => openModal(project.images.map(img => img.image), index)}
                                                >
                                                    <img src={img.image} alt={project.name} />
                                                </div>
                                            </div>
                                        ))}
                                    </Carousel>
                                )}
                                <div className="card-body">
                                    <h5 className="card-title"><strong>{project.name}</strong></h5>
                                    <p className="card-text text-secondary">{project.description}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No projects available.</p>
                )}
            </div>

            <Modal isOpen={isOpen} onClose={closeModal} nextImage={nextImage} prevImage={prevImage}>
                <img src={currentImages[photoIndex]} alt="Project" />
            </Modal>
        </div>
    );
};

export default ProjectList;






