import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <>
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="/images/general.jpg" className="d-block w-100" alt="Tous Travaux de Rénovation"/>
                        <div className="carousel-caption">
                            <h5>Tous Travaux de Rénovation</h5>
                            <p>Nous réalisons tous les travaux possibles pour l'intérieur, ainsi que des terrasses extérieures, au meilleur prix.</p>
                            <Link to="/contact">
                                <button className='btn btn-primary'>Prendre Rendez-Vous</button>
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="/images/lighting.jpg" className="d-block w-100" alt="Installation d'électricité"/>
                        <div className="carousel-caption">
                            <h5>Installation d'électricité</h5>
                            <p>Installation de luminaires, prises, interrupteurs et autres appareils.</p>
                            <Link to="/contact">
                                <button className='btn btn-primary'>Prendre Rendez-Vous</button>
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="/images/kitchen.jpg" className="d-block w-100" alt="Cuisine"/>
                        <div className="carousel-caption">
                            <h5>Cuisine</h5>
                            <p>Assemblage de meubles et équipements de cuisine.</p>
                            <Link to="/contact">
                                <button className='btn btn-primary'>Prendre Rendez-Vous</button>
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item">
                    <img src="/images/bathroom.jpg" className="d-block w-100" alt="Salle de bain"/>
                        <div className="carousel-caption">
                            <h5>Salle de bain</h5>
                            <p>Montage de tous les équipements sanitaires, carreaux et mobilier.</p>
                            <Link to="/contact">
                                <button className='btn btn-primary'>Prendre Rendez-Vous</button>
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="/images/floors_ceilings.jpg" className="d-block w-100" alt="Sols et Plafonds"/>
                        <div className="carousel-caption">
                            <h5>Sols et Plafonds</h5>
                            <p>Pose et rénovation de sols et plafonds pour une finition parfaite et durable de vos espaces.</p>
                            <Link to="/contact">
                                <button className='btn btn-primary'>Prendre Rendez-Vous</button>
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="/images/terrace.jpg" className="d-block w-100" alt="Terrasses Extérieures"/>
                        <div className="carousel-caption">
                            <h5>Terrasses Extérieures</h5>
                            <p>Conception et installation de terrasses extérieures pour profiter de vos espaces extérieurs avec style.</p>
                            <Link to="/contact">
                                <button className='btn btn-primary'>Prendre Rendez-Vous</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className="about-company">
                <div className="container py-5">
                    <h2 className="text-center">Notre Mission</h2>
                    <p className="text-center mt-4">
                        Chez SVL Construct, nous sommes fiers de notre engagement envers l'excellence dans tous les aspects de la rénovation et de la construction. 
                        Avec des années d'expérience et une équipe de professionnels qualifiés, nous offrons des services de haute qualité pour transformer votre espace en un lieu qui correspond parfaitement à vos besoins et à vos attentes. 
                        Que ce soit pour des rénovations intérieures ou extérieures, vous pouvez compter sur nous pour un travail impeccable et une attention aux détails inégalée.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Home;