import React from 'react'

export default function WhatsApp() {
  return (
    <a
        href="https://wa.me/33699764809" 
        className='whatsapp-button'
        target='_blank'
        rel='noopener noreferrer'
    >
        <img src='/images/whatsapp.png' alt='WhatsApp' className='whatsapp-icon'/>
    </a>
  )
}
