import React from 'react';

function Careers() {
  return (
    <div className='container careers'>
      <h2 className='section-title'>REJOIGNEZ NOTRE ÉQUIPE</h2>
      <p className='intro-text'>
        Chez SVL Construct, nous sommes spécialisés dans la rénovation intérieure et nous croyons que notre force réside dans notre équipe. Si vous êtes passionné par la transformation des espaces et que vous souhaitez contribuer à des projets innovants, nous aimerions avoir de vos nouvelles!
      </p>
      
      <div className="career-container py-5">
        <h2 className="text-center benefits-title">Pourquoi Travailler Avec Nous?</h2>
        
        <div className="benefits">
          <div className="benefit">
            <h4>Ambiance Collaborative</h4>
            <p>Nous valorisons le travail d'équipe et encourageons la créativité dans tous nos projets.</p>
          </div>
          <div className="benefit">
            <h4>Développement Professionnel</h4>
            <p>Chez SVL Construct, nous soutenons la croissance personnelle et professionnelle de nos employés.</p>
          </div>
          <div className="benefit">
            <h4>Projets Variés</h4>
            <p>Participez à des projets stimulants qui vous permettront de mettre en pratique vos compétences et d'apprendre de nouvelles techniques.</p>
          </div>
        </div>
      </div>
      
      <h3 className='contact-title'>CONTACTEZ-NOUS</h3>
      <p className='contact-text'>
        Si vous êtes intéressé par une carrière dans la rénovation intérieure ou si vous souhaitez en savoir plus sur nos opportunités, veuillez nous contacter à l'adresse suivante: <a href="mailto:constructsvl@gmail.com" className="email-link">constructsvl@gmail.com</a>
      </p>
      
      <h4 className='closing-text'>Merci de votre intérêt pour SVL Construct. Nous avons hâte de vous entendre!</h4>
    </div>
  );
}

export default Careers;

