import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer bg-dark text-white py-4">
    <div className="container-footer">
        <div className="row justify-content-between">
            {/* Left-aligned column */}
            <div className="col-md-4 footer-section text-left">
                <h5>À propos de nous</h5>
                <p>Nous sommes spécialisés dans la rénovation et l'amélioration de votre habitat, avec un engagement envers la qualité et la satisfaction client.</p>
            </div>
            
            {/* Center-aligned column */}
            <div className="col-md-4 footer-section text-center">
                <h5>Liens utiles</h5>
                <ul className="list-unstyled">
                    <li><Link className="text-white" to="/">Accueil</Link></li>
                    <li><Link className="text-white" to="/projects">Projets</Link></li>
                    <li><Link className="text-white" to="/careers">Carrières</Link></li>
                    <li><Link className="text-white" to="/contact">Contact</Link></li>
                </ul>
            </div>
            
            {/* Right-aligned column */}
            <div className="col-md-4 footer-section text-right">
                <h5>Contact</h5>
                <ul className="list-unstyled">
                    <li>Adresse: Paris, France</li>
                    <li>Téléphone: <span>+33 </span><span>699 </span><span>764809</span></li>
                    <li>Email: constructsvl@gmail.com</li>
                </ul>
            </div>
        </div>
        
        <div className="text-center mt-3">
            <p>&copy; 2024 SVL Construct. Tous droits réservés.</p>
        </div>
    </div>
</footer>

    );
}

export default Footer;
