import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
            formData, 
            process.env.REACT_APP_EMAILJS_USER_ID
        )        
            .then((response) => {
                console.log('Message sent successfully!')
                alert('Message sent successfully!')
            }, (error) => {
                console.log('Failed...', error);
                alert('Failed to send the message, please try again later.');
            });

        setFormData({
            name: '',
            email: '',
            phone: '',
            message: ''
        });
    };

    return (
        <div className='container'>
            <h2 id='contact-title'>CONTACTEZ-NOUS</h2>
            <p id='contact-location'>Nous opérons autour de Paris. Vous pouvez nous contacter au numéro ci-dessous ou remplir le formulaire pour nous envoyer un message.</p>
            <p id='contact-phone'><strong>Numéro de téléphone: </strong><span>+33 </span><span>699 </span><span>764809</span></p>
            <form onSubmit={handleSubmit} className='form-connect'>
                <div className='form-group'> 
                    <input 
                        autoFocus
                        placeholder='Votre nom/prenom'
                        className='form-control'
                        type='text'
                        id='name'
                        name='name'
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        placeholder='E-mail'
                        className='form-control' 
                        type='email'
                        id='email'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        placeholder='Numéro de téléphone'
                        className='form-control' 
                        type='tel'
                        id='phone'
                        name='phone'
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-group'>
                    <textarea
                        className='form-control'
                        placeholder='Laissez-nous un message pour un rendez-vous ou des questions'
                        id='message'
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type='submit' className='btn btn-primary'>
                    Envoyer
                </button>
            </form>
        </div>
    )
}

export default Contact;