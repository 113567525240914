import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import ProjectList from './components/ProjectList';
import './App.css';
import Home from './components/Home';
import Contact from './components/Contact';
import Careers from './components/Careers';
import WhatsApp from './components/WhatsApp';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className='App'>
        <Navbar />
        <Routes>
            <Route path='/' element={<Home />}  />
            <Route path='/contact' element={<Contact />} />
            <Route path='/projects' element={<ProjectList />} />
            <Route path='/careers' element={<Careers />} />
        </Routes>
        <WhatsApp />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
